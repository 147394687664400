/* stylelint-disable value-keyword-case, custom-property-pattern */

/* Custom Theming for Angular Material
For more information: https://material.angular.io/guide/theming
*/
@use '@angular/material' as mat;

// Plus imports for other components in your app.

/* Include the common styles for Angular Material. We include this here so that you only
have to load a single css file for Angular Material in your app.
Be sure that you only ever include this mixin once! */

@include mat.core;

$theme-primary: (
  50: var(--theme-primary-300),
  100: var(--theme-primary-300),
  200: var(--theme-primary-300),
  300: var(--theme-primary-300),
  400: var(--theme-primary-400),
  500: var(--theme-primary-500),
  600: var(--theme-primary-600),
  700: var(--theme-primary-700),
  800: var(--theme-primary-800),
  900: var(--theme-primary-900),
  A100: var(--theme-primary-A100),
  A200: var(--theme-primary-A200),
  A400: var(--theme-primary-A400),
  A700: var(--theme-primary-A700),
  contrast: (
    50: var(--theme-primary-contrast-300),
    100: var(--theme-primary-contrast-300),
    200: var(--theme-primary-contrast-300),
    300: var(--theme-primary-contrast-300),
    400: var(--theme-primary-contrast-400),
    500: var(--theme-primary-contrast-500),
    600: var(--theme-primary-contrast-600),
    700: var(--theme-primary-contrast-700),
    800: var(--theme-primary-contrast-800),
    900: var(--theme-primary-contrast-900),
    A100: var(--theme-primary-contrast-A100),
    A200: var(--theme-primary-contrast-A200),
    A400: var(--theme-primary-contrast-A400),
    A700: var(--theme-primary-contrast-A700),
  ),
);
$theme-accent: (
  50: var(--theme-secondary-50),
  100: var(--theme-secondary-100),
  200: var(--theme-secondary-200),
  300: var(--theme-secondary-300),
  400: var(--theme-secondary-400),
  500: var(--theme-secondary-500),
  600: var(--theme-secondary-600),
  700: var(--theme-secondary-700),
  800: var(--theme-secondary-800),
  900: var(--theme-secondary-900),
  A100: var(--theme-secondary-A100),
  A200: var(--theme-secondary-A200),
  A400: var(--theme-secondary-A400),
  A700: var(--theme-secondary-A700),
  contrast: (
    50: var(--theme-secondary-contrast-50),
    100: var(--theme-secondary-contrast-100),
    200: var(--theme-secondary-contrast-200),
    300: var(--theme-secondary-contrast-300),
    400: var(--theme-secondary-contrast-400),
    500: var(--theme-secondary-contrast-500),
    600: var(--theme-secondary-contrast-600),
    700: var(--theme-secondary-contrast-700),
    800: var(--theme-secondary-contrast-800),
    900: var(--theme-secondary-contrast-900),
    A100: var(--theme-secondary-contrast-A100),
    A200: var(--theme-secondary-contrast-A200),
    A400: var(--theme-secondary-contrast-A400),
    A700: var(--theme-secondary-contrast-A700),
  ),
);
$theme-warn: (
  50: var(--error-color),
  100: var(--error-color),
  200: var(--error-color),
  300: var(--error-color),
  400: var(--error-color),
  500: var(--error-color),
  600: var(--error-color),
  700: var(--error-color),
  800: var(--error-color),
  900: var(--error-color),
  A100: var(--error-color),
  A200: var(--error-color),
  A400: var(--error-color),
  A700: var(--error-color),
  contrast: (
    50: var(--portal-dark),
    100: var(--portal-dark),
    200: var(--portal-dark),
    300: var(--portal-dark),
    400: var(--portal-light),
    500: var(--portal-light),
    600: var(--portal-light),
    700: var(--portal-light),
    800: var(--portal-light),
    900: var(--portal-light),
    A100: var(--portal-dark),
    A200: var(--portal-dark),
    A400: var(--portal-dark),
    A700: var(--portal-light),
  ),
);

/* Define the palettes for your theme using the Material Design palettes available in palette.scss
(imported above). For each palette, you can optionally specify a default, lighter, and darker
hue. Available color palettes: https://material.io/design/color/ */
$oneportal-frontend-primary: mat.m2-define-palette($theme-primary);
$oneportal-frontend-accent: mat.m2-define-palette(
  $theme-accent,
  A200,
  A100,
  A400
);

/* The warn palette is optional (defaults to red). */
$oneportal-frontend-warn: mat.m2-define-palette($theme-warn);
$typography-config: mat.m2-define-typography-config(
  // <h1>
  $headline-5:
    mat.m2-define-typography-level(28px, 28px, 400, $letter-spacing: normal),
  // <h2>
  $headline-6:
    mat.m2-define-typography-level(16px, 16px, 500, $letter-spacing: normal),
  // Body text
  $body-2: mat.m2-define-typography-level(16px, 1.5, 400),
  // <h4>
  $body-1: mat.m2-define-typography-level(16px, 1.5, 400)
);

/* Create the theme object. A theme consists of configurations for individual
theming systems such as "color" or "typography". */
$oneportal-frontend-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $oneportal-frontend-primary,
      accent: $oneportal-frontend-accent,
      warn: $oneportal-frontend-warn,
    ),
    typography: $typography-config,
  )
);

/* Include theme styles for core and each component used in your app.
Alternatively, you can import and @include the theme mixins for each component
that you are using. */
@include mat.all-component-themes($oneportal-frontend-theme);
@include mat.all-component-typographies($typography-config);
