// Small tablets and large smartphones (landscape view)
$screen-sm: 576px;

// Small tablets (portrait view)
$screen-md: 768px;

// Tablets and small desktops
$screen-lg: 992px;

// Large tablets and desktops
$screen-xl: 1200px;

/* mixins using above breakpoints */

// Small devices
@mixin upto-sm {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

// Medium devices
@mixin upto-md {
  @media (max-width: #{$screen-md}) {
    @content;
  }
}

// Large devices
@mixin upto-lg {
  @media (max-width: #{$screen-lg}) {
    @content;
  }
}

// Extra large devices
@mixin upto-xl {
  @media (max-width: #{$screen-xl}) {
    @content;
  }
}
