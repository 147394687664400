// Declare Material setup first, so other settings can overwrite it!
@use 'breakpoint-mixin' as breakpoint;
@use 'material-basic-setup';

:root {
  --portal-white: #fff;
  --portal-light: #f6f6f7;
  --portal-dark: #4f4f4f;
  --error-color: #b00402;
  --gravel: #49454f; /* general */
  --light-grey: #d9d9d9; /* selected card color */
  --sea-shell: #f0f0f0; /* general background color and unselected card color */

  /* fallback in case theme setup via whitelabel config failed */
  --theme-primary-500: #605d62;
  --theme-primary-contrast-500: var(--portal-white);
}

html,
body {
  height: 100%;
  color: var(--gravel);
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background-color: var(--sea-shell);
}

h1 {
  color: black;
}

a {
  text-decoration: none;
  color: var(--gravel);
}

.bold {
  font-weight: 500;
}

form {
  width: 100%;
}

.space {
  display: block;
  padding: 1rem;
}

.full-width {
  width: 100%;
}

.no-margin {
  margin: 0 !important;
}

app-selection-group mat-error {
  width: 100%;
}

hr {
  height: 4px;
  background-color: var(--sea-shell);
  border: none;
  margin: 0;
}

.full-width-flex-element {
  flex-basis: 100%;
}

.list-container {
  margin: 0;
  padding-left: 24px;
}

// Input
.input-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 4%;
  flex: 2;
  justify-content: space-between;
}

.input-field {
  flex-basis: 48%;

  @include breakpoint.upto-md {
    flex-basis: 100%;
  }
}

.textarea-field {
  flex-basis: 100%;
}

.nested-input-field {
  flex-basis: 22%;
  min-width: 22%;

  @include breakpoint.upto-md {
    flex-basis: 48%;
  }
}

.small-input-field-container,
.input-field-with-postfix-container {
  display: flex;
  flex-basis: 48%;
  align-items: center;

  @include breakpoint.upto-md {
    flex-basis: 100%;
  }

  .small-input-field,
  .input-field-with-postfix {
    width: 100%;
  }
}

.small-input-field-container {
  padding-right: 40px;
}

.input-field-with-postfix-container {
  .input-field-postfix {
    margin-bottom: 20px;
  }
}

.two-column-layout-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.two-column-layout-item {
  flex-basis: 48%;

  @include breakpoint.upto-md {
    flex-basis: 100%;
  }
}

// Summary
.section-heading {
  font-weight: 500;
  text-decoration: underline;
}

.section-fields-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  column-gap: 2%;

  app-summary-section-field {
    flex-basis: 49%;

    @include breakpoint.upto-sm {
      flex-basis: 100%;
    }
  }
}

/* stylelint-disable selector-class-pattern */

// Overwrite material styles

// Radio

mat-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

mat-radio-group.input-container {
  justify-content: flex-start;
}

mat-radio-button {
  margin-right: 40px;
}

mat-radio-button,
mat-checkbox {
  span {
    white-space: normal;
  }
}

// Tooltip
.mdc-tooltip__surface {
  white-space: pre-line;
  max-width: 300px !important;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  color: var(--gravel) !important;
  background-color: var(--portal-white) !important;
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 30%),
    0 4px 8px 3px rgb(0 0 0 / 15%);
  font-size: 14px !important;
  padding: 10px 15px;
}

.tooltip-info-icon {
  cursor: pointer;
  margin-left: 1rem;
  vertical-align: bottom;
  line-height: 24px;
}

.tooltip-heading-label,
.tooltip-heading-label > .tooltip-info-icon {
  line-height: 24px !important;
}

// Error
.mat-mdc-form-field-error {
  color: var(--error-color);
}

// Checkbox

mat-checkbox {
  margin-left: -10px;
}

.mdc-checkbox {
  --mdc-checkbox-selected-checkmark-color: var(--portal-white);
}

mat-checkbox.input-field {
  label {
    min-height: 59px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

mat-checkbox .mdc-label {
  color: var(--gravel) !important;
}

mat-checkbox .mat-internal-form-field {
  -webkit-font-smoothing: unset !important;
}

// Dialog
.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 28px !important;
}

.mat-mdc-dialog-actions {
  justify-content: flex-end !important;
  padding: 0 24px 9px !important;
}

// Button
.mdc-button {
  letter-spacing: normal !important;
  font-weight: normal !important;
}

.mat-mdc-outlined-button {
  font-weight: 500 !important;
}

// NOTE: temp fix for bug on material button: https://github.com/angular/components/issues/26056
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: var(--theme-primary-contrast-500);
}

.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-label-text-color: var(--theme-accent-contrast-500);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-label-text-color: var(--theme-primary-contrast-500);
}

.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-label-text-color: var(--theme-accent-contrast-500);
}
/* stylelint-enable selector-class-pattern */

label.label-required::after {
  content: ' *';
}

.document-description {
  a {
    font-weight: 500;
    text-decoration: underline;
  }
}
